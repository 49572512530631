import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FDN, FormButton, Option, VALIDATORS } from '@intersystems/isc-form';
import { Deployment } from 'api';
import { AbstractControl } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
@Component({
  selector: 'app-configurations-form',
  templateUrl: './configurations-form.component.html',
  styleUrls: ['./configurations-form.component.scss'],
})
export class ConfigurationsFormComponent implements OnChanges, OnDestroy {
  @Input() tenantName: string;
  @Input() deployment: Deployment;
  @Output() testFields = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();
  @Output() createInterfaceConfiguration = new EventEmitter<any>();
  @Output() createTrigger = new EventEmitter<any>();

  formModel: any = {};

  bundleOptions = [
    {
      name: 'Batch',
      value: 'batch',
    },
    {
      name: 'Collection',
      value: 'collection',
    },
    {
      name: 'Document',
      value: 'document',
    },
    {
      name: 'Transaction',
      value: 'transaction',
    }
  ];

  //FHIR Server has more limited output types
  fhirServerBundleOptions = [
    {
      name: 'Batch',
      value: 'batch',
    },
    {
      name: 'Transaction',
      value: 'transaction',
    }
  ];

  healthLakeBundleOptions = [{
    name: 'Collection',
    value: 'collection'
  }
];

  bundleModeOutputOptionsSubject: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([this.bundleOptions[2]]);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private cdr: ChangeDetectorRef) {}

  configurationsFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'inputPropertiesHeader',
            key: 'inputPropertiesHeader',
            template: '<h3>Input Properties</h3>',
          },
          {
            id: 'CustomerSrcS3Bucket',
            key: 'CustomerSrcS3Bucket',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Input S3 Bucket is a required field',
            },
            data: {
              hint: 'The name of the Amazon S3 bucket that will supply the input data.',
            },
            templateOptions: {
              label: 'Input S3 Bucket',
              required: true,
            },
            validators: {
              ['inputLocation']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const inputlocationPattern =
                      /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/;
                    return inputlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 bucket name must be in valid format.',
              },
            },
          },
          {
            id: 'CustomerSrcS3Prefix',
            key: 'CustomerSrcS3Prefix',
            type: 'input',
            data: {
              hint: 'The prefix of the Amazon S3 bucket that will supply the input data.',
            },
            templateOptions: {
              label: 'Input S3 Prefix',
            },
            validators: {
              ['inputPrefix']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const inputlocationPattern = /^(?!\/).*[^\/]$/;
                    return inputlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 prefix must be specified without leading and trailing slash.',
              },
            },
          },
          {
            id: 'targetPropertiesHeader',
            key: 'targetPropertiesHeader',
            template: '<h3>Target Properties</h3>',
          },
          {
            id: 'targetType',
            key: 'targetType',
            type: 'select',
            templateOptions: {
              label: 'Target type',
              required: true,
              options: [
                {
                  name: 'FHIR Server',
                  value: 'fhir_server',
                },
                {
                  name: 'Amazon HealthLake',
                  value: 'healthlake'
                },
                {
                  name: 'S3 Bucket',
                  value: 's3',
                },
              ],
            },
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Target Type is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
            },
            hooks: {
              onInit: field =>
                field.formControl.valueChanges
                  .pipe(
                    tap(changes => this.onTargetTypeChange(changes.value)),
                    takeUntil(this._unsubscribeAll),
                  )
                  .subscribe(),
            },
          },
          {
            id: 'FHIRAPIKey',
            key: 'FHIRAPIKey',
            type: 'input',
            data: {
              hint: 'Replace FHIR Server API Key with the new value if set',
            },
            templateOptions: {
              label: 'New API Key',
            },
            hideExpression: model => (model.targetType ? model.targetType.value !== 'fhir_server' : true),
          },
          {
            id: 'FHIRServer',
            key: 'FHIRServer',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Endpoint is a required field',
            },
            data: {
              hint: 'FHIR Server endpoint.',
            },
            templateOptions: {
              label: 'Endpoint',
              required: true,
            },
            validators: {
              ['endpoint']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    if (control.value.split('://').length > 1) {
                      control.patchValue(control.value.split('://')[1]);
                    }
                    const endpointPattern =
                      /^(?=.{1,255}$)[0-9A-Za-z](?:(?:[0-9A-Za-z]|-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|-){0,61}[0-9A-Za-z])?)*\.?$/;
                    return endpointPattern.test(control.value);
                  }
                },
                message: () => 'Endpoint name must be a valid hostname.',
              },
            },
            hideExpression: model => (model.targetType ? model.targetType.value !== 'fhir_server' : true),
          },

          {
            id: 'HealthLakeDatastoreID',
            key: 'HealthLakeDatastoreID',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Amazon HealthLake ID is a required field',
            },
            data: {
              hint: `The data store identifier, which you can find in the Amazon HealthLake console
              (ex. 1f2f459836ac6c513ce899f9e4f66a59)`,
            },
            templateOptions: {
              label: 'Amazon HealthLake ID',
              required: true,
            },
            hideExpression: model => (model.targetType ? model.targetType.value !== 'healthlake' : true),
          },
          {
            id: 'HealthLakeRegion',
            key: 'HealthLakeRegion',
            type: 'select',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Amazon HealthLake Region is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              hint: `Region of Amazon HealthLake`,
            },
            templateOptions: {
              label: 'Amazon HealthLake Region',
              required: true,
              options: [
                {
                  name: 'Virginia (us-east-1)',
                  value: 'us-east-1',
                },
                {
                  name: 'Ohio (us-east-2)',
                  value: 'us-east-2',
                },
                {
                  name: 'Oregon (us-west-2)',
                  value: 'us-west-2',
                },
              ],
            },
            hideExpression: model => (model.targetType ? model.targetType.value !== 'healthlake' : true),
          },

          {
            id: 'CustomerDestS3Bucket',
            key: 'CustomerDestS3Bucket',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Target Location is a required field',
            },
            data: {
              hint: 'The location of the Amazon S3 bucket that will store the transformed data.',
            },
            templateOptions: {
              label: 'Target S3 Bucket',
              required: true,
            },
            validators: {
              ['targetLocation']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const targetlocationPattern =
                      /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/;
                    return targetlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 bucket must be in valid format.',
              },
            },
            hideExpression: model => (model.targetType ? model.targetType.value !== 's3' : true),
          },

          {
            id: 'CustomerDestS3Prefix',
            key: 'CustomerDestS3Prefix',
            type: 'input',
            data: {
              hint: 'The prefix of the Amazon S3 bucket that will store the target data.',
            },
            templateOptions: {
              label: 'Target S3 Prefix',
            },
            validators: {
              ['inputPrefix']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const inputlocationPattern = /^(?!\/).*[^\/]$/;
                    return inputlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 prefix must be specified without leading and trailing slash.',
              },
            },
            hideExpression: model => (model.targetType ? model.targetType.value !== 's3' : true),
          },

          {
            id: 'BundleMode',
            key: 'BundleMode',
            type: 'select',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Output Mode is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              hint: `Output Mode`,
            },
            templateOptions: {
              label: 'Output Mode',
              required: true,
              options: this.bundleOptions,
              asyncOptions: () => this.bundleModeOutputOptionsSubject.asObservable(),
            },
            hideExpression: model =>
              model.targetType ? model.targetType.value !== 'fhir_server' && model.targetType.value !== 's3' && model.targetType.value!=='healthlake' : true,
          },
          {
            id: 'ErrorBehavior',
            key: 'ErrorBehavior',
            type: 'select',
            templateOptions: {
              label: 'Error Behavior',
              required: true,
              options: [
                {
                  name: 'Ignore',
                  value: 'ignore',
                },
                {
                  name: 'Pause',
                  value: 'pause',
                },
              ],
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              onlySaveUniqueValueField: true,
            },
          },
          {
            id: 'Archive',
            key: 'Archive',
            type: 'select',
            templateOptions: {
              label: 'Archive Strategy',
              required: true,
              options: [
                {
                  name: 'Delete',
                  value: 'delete',
                },
                {
                  name: 'Ignore',
                  value: 'ignore',
                },
                {
                  name: 'Move',
                  value: 'move',
                },
              ],
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              onlySaveUniqueValueField: true,
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      id: 'btn-activate-trigger',
      text: 'Activate Trigger',
      buttonClass: 'secondary',
      type: 'submit',
      disabled: (_formModel, _formOptions, form) => form.valueChanges.pipe(map(() => !form.pristine)),
      callback: (_event, _button, formModel) => this.createTrigger.emit(formModel),
    },
    {
      id: 'btn-cancel',
      text: 'Cancel',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => null,
    },
    {
      id: 'btn-save',
      text: 'Save',
      buttonClass: 'primary',
      type: 'submit',
      disabledIfFormInvalid: true,
      callback: (_event, _button, formModel) => this.createInterfaceConfiguration.emit(formModel),
    },
  ];

  ngOnChanges(change) {
    if (change.deployment.currentValue) {
      this.formModel = { ...change.deployment.currentValue.configuration };
      if (!this.formModel.ErrorBehavior) this.formModel.ErrorBehavior = 'ignore';
      if (!this.formModel.Archive) this.formModel.ErrorBehavior = 'delete';
      this.formModel.BundleMode = this.getOption('BundleMode', this.formModel.BundleMode);
      this.formModel.HealthLakeRegion = this.getOption('HealthLakeRegion', this.formModel.HealthLakeRegion);
      if (this.formModel.CustomerDestS3Bucket) this.formModel.targetType = this.getOption('targetType', 's3');
      if (this.formModel.HealthLakeRegion) this.formModel.targetType = this.getOption('targetType', 'healthlake');
      if (this.formModel.FHIRServer) this.formModel.targetType = this.getOption('targetType', 'fhir_server');
      this.onTargetTypeChange(this.formModel.targetType.value);
    }
  }

  getJsonExample(): any {
    const jsonExample = {
      Version: '2012-10-17',
      Statement: [
        {
          Sid: 'Allow S3 access for FTS',
          Effect: 'Allow',
          Principal: {
            AWS: [
              'arn:aws:iam::' + this.deployment.account + ':role/' + this.deployment.deploymentid + '-lambdaRole',
              'arn:aws:iam::' +
                this.deployment.account +
                ':role/transform-' +
                this.deployment.deploymentid +
                '-taskRole',
            ],
          },
          Action: ['s3:GetObject', 's3:PutObject', 's3:DeleteObject'],
          Resource: 'arn:aws:s3:::' + this.formModel.CustomerSrcS3Bucket + '/*',
        },
        {
          Sid: 'Allow S3 notifications',
          Effect: 'Allow',
          Principal: {
            AWS: 'arn:aws:iam::' + this.deployment.account + ':role/' + this.deployment.deploymentid + '-lambdaRole',
          },
          Action: ['s3:GetBucketNotification', 's3:PutBucketNotification'],
          Resource: 'arn:aws:s3:::' + this.formModel.CustomerSrcS3Bucket + '',
        },
      ],
    };
    return jsonExample;
  }

  getJsonExampleDest(): any {
    const jsonExample = {
      Version: '2012-10-17',
      Statement: [
        {
          Sid: 'Allow S3 access for FTS',
          Effect: 'Allow',
          Principal: {
            AWS: 'arn:aws:iam::' + this.deployment.account + ':role/' + this.deployment.deploymentid + '-lambdaRole',
          },
          Action: ['s3:GetObject', 's3:PutObject', 's3:DeleteObject'],
          Resource: 'arn:aws:s3:::' + this.formModel.CustomerDestS3Bucket + '/*',
        },
      ],
    };
    return jsonExample;
  }

  getOption(field, value) {
    const fieldFDN = this.configurationsFDN.sections[0].fields.find(item => item.id == field);
    return fieldFDN.templateOptions.options.find(item => item.value == value);
  }

  onTargetTypeChange(value: string): void {
    if (value === 'fhir_server') {
      // Only valid Outputs for FHIR Server are Batch and Transaction
      this.bundleModeOutputOptionsSubject.next(this.fhirServerBundleOptions);
      const deploymentBundleMode = this.deployment.configuration.BundleMode;
      let bundleModeOption: { name: string; value: string };

      if (deploymentBundleMode === '') {
        bundleModeOption = {
          name: 'Transaction',
          value: 'transaction',
        };
      } else {
        bundleModeOption = {
          name: deploymentBundleMode,
          value: deploymentBundleMode,
        };
      }

      this.formModel = {
        ...this.formModel,
        BundleMode: {
          name: bundleModeOption.name,
          value: bundleModeOption.value,
        },
      };
    }

    if (value === 's3') {
      this.bundleModeOutputOptionsSubject.next(this.bundleOptions);
      const deploymentBundleMode = this.deployment.configuration.BundleMode;
      let bundleModeOption: { name: string; value: string };

      if (deploymentBundleMode === '') {
        bundleModeOption = {
          name: 'Document',
          value: 'document',
        };
      } else {
        bundleModeOption = {
          name: deploymentBundleMode,
          value: deploymentBundleMode,
        };
      }

      this.formModel = {
        ...this.formModel,
        BundleMode: {
          name: bundleModeOption.name,
          value: bundleModeOption.value,
        },
      };
    }


    if (value === 'healthlake') {
      this.bundleModeOutputOptionsSubject.next(this.healthLakeBundleOptions);
      const deploymentBundleMode = this.deployment.configuration.BundleMode;
      let bundleModeOption: { name: string; value: string };

      if (deploymentBundleMode === '') {
        bundleModeOption = {
          name: 'Collection',
          value: 'collection',
        };
      } else {
        bundleModeOption = {
          name: deploymentBundleMode,
          value: deploymentBundleMode,
        };
      }

      this.formModel = {
        ...this.formModel,
        BundleMode: {
          name: bundleModeOption.name,
          value: bundleModeOption.value,
        },
      };
    }
    
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
